html,
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #703be7;
}

#root {
  min-height: 100vh;
  overflow-y: auto;
}
.App {
  width: 100%;
  overflow-x: hidden;
}

.franklinNine {
  font-family: "Libre Franklin", Arial, sans-serif;
  font-weight: 900;
  font-style: normal;
}

.franklinThree {
  font-family: "Libre Franklin", Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.franklinOne {
  font-family: "Libre Franklin", Arial, sans-serif;
  font-weight: 100;
  font-style: normal;
}

.bgPurple {
  background-color: #703be7;
}
